// Table
.table_responsive {
  overflow-x: auto;
  min-height: 20rem;
  .table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    thead {
      background: $purple;
      width: 100%;
      @extend .text_capitalize;
      tr {
        td {
          @extend .fs_14;
          line-height: 2rem;
          color: $black;
          @extend .text_capitalize;
          @extend .fw_500;
          @extend .white_space_nowrap;
          padding: 1.15rem 1rem; 
          .arrow_up{
            width: 1.6rem;
            @extend .cursor_pointer;
            // @extend .ml_05;
              img{
                  width: .8rem;
                  @extend .img_object_center;
              }
          }
        }
      }
    }
    tbody{
        tr{
            td{
                @extend .fs_13;
                line-height: 2rem;
                color: $text_think;
                @extend .text_capitalize;
                @extend .fw_500;
                padding: 1.15rem 1rem;                                
                a{
                    color: $text_blue;
                }
                .actions_icon{
                    width: 1.4rem;
                    @extend .d_flex_center;
                    @extend .cursor_pointer;
                    @extend .mr_05;
                    img{
                        width: 1.4rem;
                        @extend .img_object_center;
                    }
                }
            }
        }
    }
    &.clinic_data_list {
      min-width: 110rem;
      table-layout: fixed;
      td {
        &:nth-child(1) {
          width: 3%;
        //   padding-left: 5rem;
        }
        &:nth-child(2) {
          width: 16%;
        }
        &:nth-child(3) {
          width: 16%;
        }
        &:nth-child(4) {
          width: 22%;
        }
        &:nth-child(5) {
          width: 8%;
        }
        &:nth-child(6) {
          width: 8%;
        }
        &:nth-child(7) {
          width: 11%;
        }
        &:nth-child(8) {
          width: 10%;
        }
        &:nth-child(9) {
          width: 8%;
        }
      }
    }
  }
}
