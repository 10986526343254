.clinics{
    width: 100%;
    .heading_content{
        @extend .justify_content_between;
        .form_group{
            @extend .position_relative;
            margin: 0;
            .form_control{
                min-width: 30.9rem;
                @extend .radius_20;
                padding-left: 3.3rem;
            }
            .serch_icon{
                width: 1.9rem;
                height: 1.9rem;
                @extend .position_absolute;
                top: 1.3rem;
                left: .7rem;
                @extend .cursor_pointer;
                img{
                    width: 1.9rem;
                    @extend .img_object_center;
                }
            }
        }
    }
}