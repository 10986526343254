.clinic_header {
  background: rgba(154, 176, 189, 0.19);
  display: flex;
  align-items: center;
  padding: 2.1rem 8rem;

  .breadcrumb {
    width: calc(50% - 5rem);
    display: flex;
    li {
      list-style: none;
      color: rgba(0, 0, 0, 0.35);

      &.active {
        color: #000;
      }

      & + li {
        &:before {
          content: "";
          margin-left: 10px;
          padding-left: 20px;
          background-image: url("../images/arrow_right.svg");
          background-repeat: no-repeat;
          background-position: calc(100% - 1rem) center;
        }
      }
    }
  }
  .clinic_steps {
    width: 10rem;
    display: flex;
    justify-content: center;
    li {
      list-style: none;
    }
    span {
      width: 3.2rem;
      height: 3.2rem;
      font-size: 12px;
      background: #8cc7ec;
      color: #fff;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0.5rem;

      &.active {
        background: #457b9d;
        cursor: pointer;
      }
    }
  }
}

.clinic {
  display: flex;
//   flex-direction: column;
  .col-3 {
    width: calc(25% - 1.8rem);
  }
  .col-4 {
    width: calc(33.33% - 1.8rem);
  }
  .col-5 {
    width: calc(41.66% - 1.8rem);
  }
  .col-6 {
    width: calc(50% - 1.8rem);
  }
  .col-7 {
    width: calc(58.33% - 1.8rem);
  }
  .col-8 {
    width: calc(66.66% - 1.8rem);
  }
  .col-9 {
    width: calc(75% - 1.8rem);
  }
  .col-12 {
    width: calc(100%);
  }

  .skip_for_now_btn{
    padding-top: 7rem;
  }
  &_form {
    max-width: 64.7rem;
    width: 100%;
    padding: 1.5rem 8rem;

    .card {
      padding: 2rem 2.5rem;
      @extend .fullWidth;

      &_bg_gray {
        background: #e8e8e8;
        border-radius: 3px;
      }
    }
    label {
      margin-bottom: 0.5rem;
      @extend .fullWidth;
    }
    .form_group {
      margin-bottom: 1rem;

      .text_add {
        span {
          position: absolute;
          right: -7rem;
          top: 45%;
          transform: translateY(-50%);
          text-align: left;
          width: 6rem;
          text-transform: capitalize;
        }
      }
    }
    .form_control {
      border-radius: 1rem;
    }
    .form_row {
      display: flex;

      .col-6:not(:first-child) {
        margin-left: 3.5rem;
      }
    }
    .form_action {
      margin-top: 7rem;
    }
  }
}
.fill {
  position: relative;
  &:after {
    content: "";
    height: 0.8rem;
    width: 0.8rem;
    float: right;
    background: $red;
    border-radius: 100%;
    overflow: hidden;
    top: calc(50% - 0.5rem);
    right: -1.7rem;
    position: absolute;
  }
}
.errorMsg {
  color: $red;
}
