@mixin boxSizing($property){
    -moz-box-sizing:    $property;
    -webkit-box-sizing: $property;
    box-sizing:         $property;
}

@mixin appearance($property){
    -webkit-appearance: $property; /* Safari & Chrome */
    -moz-appearance:    $property; /* Firefox */
    -o-appearance:      $property; /* Opera */
    appearance:         $property;
}

@mixin transform($property){
    -webkit-transform:  $property; /* Safari & Chrome */
    -moz-transform:     $property; /* Firefox */
    -ms-transform:      $property;
    -o-transform:       $property; /* Opera */
    transform:          $property;
}

@mixin transition($property){
    -webkit-transition: $property; /* Safari & Chrome */
    -moz-transition:    $property; /* Firefox */
    -ms-transition:     $property;
    -o-transition:      $property; /* Opera */
    transition:         $property;
}

@mixin boxShadow($property){
    -webkit-box-shadow: $property; /* Safari & Chrome */
    -moz-box-shadow:    $property; /* Firefox */
    -ms-box-shadow:     $property;
    -o-box-shadow:      $property; /* Opera */
    box-shadow:         $property;
}