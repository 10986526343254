.btn {
    @extend .fs_14;
    line-height: 2rem;
    @extend .text_center;
    @extend .border_0;
    @extend .radius_10;
    @extend .cursor_pointer;
    color: $white;
    padding: 1.1rem 2.5rem;
    background: $dark_silver;
    @include transition(all .3s ease-in-out);
    &:hover{
        background: rgba($dark_silver, 0.7);
        color: $white;
    }
}
.btn_default {
    @extend .fs_14;
    line-height: 2rem;
    @extend .text_center;
    @extend .border_0;
    @extend .radius_10;
    @extend .cursor_pointer;
    color: $gray;
    padding: 1.1rem 2.5rem;
    min-width: 15.4rem;
    background: $light_gray;
    @include transition(all .3s ease-in-out);
    &:hover{
        // color: $white;
        background: #d1d3d7;
    }
}
.btn_primary {
    @extend .fs_14;
    line-height: 2rem;
    @extend .text_center;
    @extend .border_0;
    @extend .radius_10;
    @extend .cursor_pointer;
    color: $white;
    padding: 1.1rem 2.5rem;
    min-width: 15.4rem;
    background: $maroon;
    @include transition(all .3s ease-in-out);
    &:hover{
        background: #9f0622;
    }
}
