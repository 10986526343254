.header{
    width: 100%;
    @extend .d_flex;
    @extend .align_items_center;
    @extend .justify_content_between;
    padding: 0.6rem 1.5rem;
    .header_profile{
        max-width: 40rem;
        @extend .d_flex;
        @extend .align_items_center;
        @extend .justify_content_end;
        .profile{
            @extend .d_flex;
            @extend .align_items_center;
            &_name{
                @extend .fs_14;
                line-height: 2rem;
                @extend .text_uppercase;
                color: $black;
                @extend .fw_600;
                @extend .mr_1;
            }
            &_pic{
                @extend .mr_1;
                img{
                    width: 4rem;
                    height: 4rem;
                    @extend .img_object_center;
                    border: .25rem solid $white;
                    @extend .radius_rounded;
                    filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.3));
                    @extend .cursor_pointer;
                }
            }
        }
        .drop_down{
            @extend .mr_2;  
            @extend .position_relative;
            &_list{
                @extend .position_absolute;
                min-width: 18.4rem;
                background: $white;
                top: 4rem;
                right: -2rem;
                @extend .radius_10;
                padding: 2.5rem 2rem;
                box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.15);
                &::before{
                    content: '';
                    background: url(../images/drop_down_ceross_icon.png) no-repeat;
                    width: 1.3rem;
                    height: 1.3rem;
                    display: block;
                    position: absolute;
                    top: -.7rem;
                    right: 1.8rem;
                }
                li{
                    @extend .text_right;
                    @extend .mb_1;
                    @extend .position_relative;
                    a{
                        @extend .fs_12;
                        line-height: 1.5rem;
                        color: $text_gray;
                        @extend .fw_500;
                        &.active{
                            color: $black;
                            span{
                                @extend .d_inline_block;
                                @extend .position_absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: -10px;
                            }
                        }
                    }
                }
                li:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .notification{
            @extend .position_relative;
            @extend .cursor_pointer;
            img{
                width: 2.4rem;
                height: 2.4rem;
                @extend .img_object_center;                
            }
            &::before{
                content: "3";
                width: 1.8rem;
                height: 1.8rem;
                background: $light_red;
                color: $white;
                @extend .fs_9;
                line-height: 2rem;
                border: .1rem solid $white;
                @extend .d_flex_center;
                @extend .radius_rounded;
                @extend .position_absolute;
                bottom: 0;
                right: -8px;
            }
        }
    }       
}