@font-face {
    font-family: montserrat_thin;
    src: url('../fonts/montserrat_thin.ttf');
}
@font-face {
    font-family: montserrat_light;
    src: url('../fonts/montserrat_light.ttf');
}
@font-face {
    font-family: montserrat_regular;
    src: url('../fonts/montserrat_regular.ttf');
}
@font-face {
    font-family: montserrat_semibold;
    src: url('../fonts/montserrat_semibold.ttf');
}
@font-face {
    font-family: montserrat_bold;
    src: url('../fonts/montserrat_bold.ttf');
}

.montserrat_thin{font-family: montserrat_thin; letter-spacing: .03rem;}
.montserrat_light{font-family: montserrat_thin; letter-spacing: .03rem; font-weight: bold;}
.montserrat_regular{font-family: montserrat_regular; letter-spacing: .03rem;}
.montserrat_semibold{font-family: montserrat_semibold; letter-spacing: .03rem;}
.montserrat_bold{font-family: montserrat_bold; letter-spacing: .03rem;}