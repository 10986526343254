$deep_black: #1A1A1A;
$light_black:#1E1E1E;
$black: #000000;
$white: #ffffff;
$purple: #A8ABE0;
$text_blue:#3079EF;
$blue_dot:#1784E9;
$dark_blue: #457B9D;
$light_blue: #C8D7E0;
$maroon: #C00C2D;
$red: #DE2B2B;
$light_red:#F84747;
$gray: #868686;
$gray_think: #F8F8F8;
$gray_think_border: #DEDEDE;
$light_gray: #E8EAF0;
$silver_gray: #495568;
$light_silver: #BFC6CF;
$dark_silver:#3E4A58;
$gray_label: #595959;
$gray_place: #C4C4C4;
$gray_solid:#BCBCBC;
$text_gray: #999999;
$text_think: #636363;

