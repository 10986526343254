.signin{
    @extend .position_relative;
    .sign_banner{
        @extend .position_relative;
        img{
            width: 100%;
            height: calc(100vh - 31rem);
            @extend .img_object_center;
        }
    }
    .sigin_banner{
        height: calc(100vh - 31rem);
        background: url(../images/signin_banner.jpg) no-repeat;
        background-size: cover;
        background-position: right;
        @extend .d_flex;
        @extend .align_items_end;
        @extend .justify_content_center;
        margin-bottom: 17rem;
    }
    .container{
        width: 100%;
        max-width: 144rem;
        @extend .mx_auto;
        .header_logo{
            @extend .position_absolute;
            top: 0;
        }
    }
    .sign_box{
        width: 100%;
        max-width: 55.4rem;
        @extend .mx_auto;
        @extend .radius_6; 
        overflow: hidden;
        box-shadow: 0px 0px 20px rgba(214, 214, 214, 0.5);
        margin-bottom: -3.1rem;
        .account_system{
            background-color: $dark_blue;   
            padding: 1.6rem 6.4rem;
        }
        .admin_sigin{
            padding: 3.3rem 5.85rem;
            background: $white;
            h2{
                @extend .mb_60;
            }
            .form_group{
                .form_control{
                    padding-right: 4rem;
                }
                ::placeholder{
                    color: $gray_place;
                }
                span{
                    @extend .position_absolute;
                    right: 1.5rem;
                    top: 3.3rem;
                    @extend .cursor_pointer;
                }
            }
        }
        .btn_primary{
            min-width: 26.7rem;
        }
    }
}